* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-family: "Inter";
  font-display: swap;
  height: 100%;
  width: 100%;
  background-color: #F5F7F9;
}
@font-face {
  font-family: "Inter";
  font-display: swap;
  src: url("./assets/fonts/Inter-Regular.woff");
}

#root {
  height: 100%;
}
