/* Basics */
.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder *,
.mapboxgl-ctrl-geocoder *:after,
.mapboxgl-ctrl-geocoder *:before {
  box-sizing: border-box;
}

.mapboxgl-ctrl {
  transform: none;
}

.mapboxgl-ctrl-geocoder {
  width: 100%;
  height: 48px;
  display: flex;
  padding: 16px;
  background: transparent;
  border-radius: 4px;
  border: 1px solid #DEDEDE;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}

.mapboxgl-ctrl-geocoder:hover {
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.mapboxgl-ctrl-geocoder--input {
  font: inherit;
  color: rgba(0, 0, 0, 0.87);  
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 25px 7px 0;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  text-overflow: ellipsis;
  -webkit-tap-highlight-color: transparent;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none; /* hide input clear button in IE */
}

.mapboxgl-ctrl-geocoder--input:focus {
  outline: 0;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  position: absolute;
  display: none;
}

.mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 6px 4px rgba(0,0,0,.1);
}

/* Suggestions */
.mapboxgl-ctrl-geocoder .suggestions {
  background-color: #fff;
  border-radius: 4px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  top: 110%; /* fallback */
  top: calc(100% + 6px);
  z-index: 1000;
  overflow: hidden;
  font-size: 14px;
}

.mapboxgl-ctrl-geocoder .suggestions.none {
  display: none !important;
}

.mapboxgl-ctrl-bottom-left .suggestions,
.mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  cursor: default;
  display: block;
  padding: 10px 12px;
  color: #555555;
  opacity: 0.8;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a,
.mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  color: #555555;
  opacity: 1;
  background-color: #f3f3f3;
  text-decoration: none;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title,
.mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  fill: #7c7c7c;
  stroke: aliceblue;
}

.mapboxgl-ctrl-geocoder--icon-search {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.mapboxgl-ctrl-geocoder--button {
  right: 13px;
  top: 13px;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: #fff;
  line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 28px;
  height: 28px;
  fill: #7c7c7c;
  stroke: #7c7c7c;
  right: 8px;
  top: 8px;
  -moz-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  -webkit-animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
  animation: rotate 0.8s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.mapbox-gl-geocoder--error {
  color:#909090;
  padding: 10px 12px;
  font-size: 14px;
  text-align: center;
}

/* Animation */
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Media queries*/
@media screen and (min-width: 640px) {}
